import {VueController} from '@/service/support/vuex-controller';
import vaultService from '@/service/vault-service';
import * as awsVars from '@/config/aws-exports';
let awsmobile = awsVars.default(process.env);
import moment from "moment";
import toMaterialStyle from 'material-color-hash';
import orgService from "@/service/org-service";
import {EventBus} from "@/service/support/event-bus";
import Auth from "@aws-amplify/auth";

export default VueController("Vaults", {
    data() {
        return {
            newVaultName: "",
            viewMode: 1,
            vaultMax: '',
            disableAddButton: false,
            snackBar: false,
            snackBarNotify: "",
            snackBarType: "",
            logoBucket: null,
	        searchVault: '',
			privateMode: false,
			vaultsTablePage: 1,
			vaultsTablePageCount: 0,
			vaultsTableItemsPerPage: 50
        };
    },
    state: [
        "vaults",
        "cognito",
	    "modals",
        "organization",
	    "organizationId",
        "user",
	    "pageIsLoading",
	    "embeddedFullScreen"
    ],
    computed: {
    	hasExternalVault() {
    		return !this.organization || this.vaults.find(vault => vault._deleted !== 1 && vault.org_id !== this.organization.id);
	    },
		userCanCreateVaults() {
			return this.cognito &&
				(this.cognito.attributes["custom:mutableUserType"] === "admin" ||
					(this.cognito.attributes["custom:mutableUserType"] === "member" &&
						this.organization &&
						!!this.organization.permissions.find(perm => perm['create_vault'] === true && perm.user_id === this.user.id && perm._deleted !== 1)
					)
				);
		},
	    responsiveVaultHeaderLayout() {
			let headers = [{text: "Client Portal", value: "name", sortable: true, width: "60%", align: "start"}];
			let anyLargeFirmOrHigherVaults = !!this.vaults.find(vault => vault._deleted !== 1 && vault.orgTier !== "professional");

			if (anyLargeFirmOrHigherVaults) {
				headers.push({text: "Tasks", value: "num_tasks_open", sortable: true, width: "180px", align: "start"});
			}

			if (this.$vuetify.breakpoint.name === "sm") {
				headers.push({text: "Members", value: "numberOfMembers", sortable: false});
			} else if (this.$vuetify.breakpoint.name !== "xs") {
				if (this.organization && this.organization.tier.toLowerCase() !== 'professional' && this.organization.tier.toLowerCase() !== 'starter') {
					headers.push({text: "Earliest Due Date", value: "earliest_open_task_date", sortable: true, align: 'start', width: "140px"});
				}

				headers.push({text: "Last File Uploaded", value: "_lastChangedAt", sortable: true, width: "150px"});
				headers.push({text: "Guests", value: "numberOfMembers", sortable: false, width: "140px"});
				headers.push({text: "Actions", value: "actions", sortable: false, width: "120px"});
			}
			return headers;
	    },
        vaultCeiling() {
            if (this.organization) {
                if (this.organization.tier.toLowerCase() !== 'enterprise') {
                    return this.vaults.length >= this.vaultMax-20;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        },
        filterDeletedVaults() {
            if (this.vaults) {
                return this.vaults.filter(vault => vault._deleted !== 1);
            }
        },
	    vaultsNotDeletedAndAccepted() {
    		//console.time('vault filter');
        	let nvdaa = [];
        	let self = this;

		    if (self.vaults && self.cognito) {
		    	let userId = self.cognito.attributes.sub;
		    	let userType = self.cognito.attributes["custom:mutableUserType"];

				let memberCanAccessVaults = false;
				if (self.organization) {
					let memberOrgPermissions =
						self.organization.permissions.find(perm =>
							perm._deleted !== 1 && perm.invitation_accepted === "true" && perm.user_id === userId
						);
					if (memberOrgPermissions && memberOrgPermissions.access_vault === true) {
						memberCanAccessVaults = true;
					}
				}

			    nvdaa = self.vaults.filter(vault =>
				    vault._deleted !== 1
				    && ((self.user && userType !== "guest" && vault.org_id === self.user.memberOf && (userType === "admin" || memberCanAccessVaults)) ||
						vault.permissions && vault.permissions.some(perm =>
				        	perm._deleted !== 1 &&
				        	(perm.user_id === userId && perm.invitation_accepted === "true")
						))
			    );
			    let internalVaults = nvdaa.filter(vault => self.organizationId && vault.org_id === self.organizationId);
			    let externalVaults = nvdaa.filter(vault => !self.organizationId || vault.org_id !== self.organizationId);
			    nvdaa = externalVaults.concat(internalVaults);
		    }

		    nvdaa = nvdaa.sort((a, b) => {
		    	let sortResult = 0;

			    if (self.isFavorite(a.id) && !self.isFavorite(b.id)) {
				    sortResult = -1;
			    } else if (!self.isFavorite(a.id) && self.isFavorite(b.id)) {
				    sortResult = 1;
			    } else {
				    sortResult = a.name.localeCompare(b.name, 'en', { sensitivity: 'base' })
			    }

			    return sortResult;
		    });
			//console.timeEnd('vault filter');
		    return nvdaa;
	    },
	    vaultsNotDeletedAndPendingInvitation() {
		    if (this.vaults && this.cognito) {
			    let userId = this.cognito.attributes.sub;
			    let userType = this.cognito.attributes["custom:mutableUserType"];

			    return this.vaults.filter(vault =>
				    vault._deleted !== 1
				    && ((userType === "guest") || (this.user && vault.org_id !== this.user.memberOf))
				    && vault.permissions && vault.permissions.some(perm =>
					    perm.user_id === userId && perm.invitation_accepted.match(/none/) && perm._deleted !== 1
				    ));
		    } else {
			    return [];
		    }
	    }
    },
	created() {
		this.logoBucket = awsmobile.aws_org_logos_s3_bucket;
	},
    mounted() {
		this.pageIsLoading = true;
        let self = this;

		let _privateMode = !!self.getLocalStoreValue("privateMode");
		self.privateMode = _privateMode;

	    if (self.cognito.attributes["custom:mutableUserType"] === "member"
		    || self.cognito.attributes["custom:mutableUserType"] === "admin") {
		    try {
			    Tawk_API.showWidget();
		    } catch (tawkError) {
			    //console.error("Unable to load Tawk.to.");
		    }
	    }

        if (self.$route.query.embedded === "true" || self.$route.query.embedded === true) {
		    self.embeddedFullScreen = true;
	    }

        if (self.cognito && (self.cognito.attributes['custom:mutableUserType'] !== "admin" && self.cognito.attributes['custom:mutableUserType'] !== "member")) {
            this.viewMode = 1;
        } else {
	        this.viewMode = 0;
        }
        self.logoBucket = awsmobile.aws_org_logos_s3_bucket;
        self.vaults = [
            {
                name: "placeholder",
                id: 0,
                org_id: 0,
                placeholder: true
            }
        ];
		//console.time('getVaults');

        vaultService.query("getVaultsByOrgId", undefined, undefined, {vuexCache: false})
            .then(async response => {
				self.vaults = [];

				//console.timeEnd('getVaults');
            	let tmpOrgId = null;
				let updateVaults = false;
            	for (let r = 0; r < response.length; r++) {

            		if (!tmpOrgId && response[r].org_id) {
			            tmpOrgId = response[r].org_id;
		            } else if (response[r].org_id !== tmpOrgId) {
            			tmpOrgId = null;
            			break;
		            }
	            }
				if(self.organization){
					for(let i=0;i<response.length; i++){
						//Double check to make sure org is on the vault level
						if(self.organization && self.organization.id && response[i].org_id === self.organization.id && response[i].orgTier !== self.organization.tier){
							updateVaults = true;
						}
					}
				}
				if(updateVaults){
					vaultService.mutation("updateVaultForTier",{}).then(response =>{
						for (let i = 0; i < self.vaults.length; i++) {
							if (self.vaults[i].org_id === self.organization.id) {
								self.vaults[i].orgTier = self.organization.tier;
							}
						}
					}).catch(e =>{
						vaultService._log("Updating vaults to new tier failed : " + JSON.stringify(e));
					});
				}
            	if (tmpOrgId) {
		            self.organizationId = tmpOrgId;
		            EventBus.$emit("refreshlazy", {oldId: "", newId: self.organizationId});
	            }

            	//DONE: If 1 vault, set org id
	            if (self.$route.query.accept && response && response.length === 1 && response[0].id === self.$route.query.accept) {
		            self.acceptInvite(response[0], true);
	            } else if (response && response.length === 1 && !self.isVaultPendingInvitation(response[0]) && self.cognito && self.cognito.attributes["custom:mutableUserType"] === "guest") {
					self.$router.push({name: 'Portal', params: {vaultid: response[0].id, orgid: response[0].org_id, folderid: 'main'}});
	            } else {
		            if (self.organization) {
		            	self.organizationId = self.organization.logo_s3_path ? self.organization.logo_s3_path : "none";
						self.organizationId = self.organizationId.replace(".png", "");
			            switch (self.organization.tier.toLowerCase()) {
				            case 'starter':
					            self.vaultMax = 50;
					            break;
				            case 'professional':
					            self.vaultMax = 1000;
					            break;
				            case 'large firm':
					            self.vaultMax = 10000;
					            break;
				            case 'enterprise' :
					            self.vaultMax = 'unlimited'
					            break;
				            default:
					            self.vaultMax = 0;
			            }
		            } else {
		            	self.organizationId = "none";
		            }

					self.vaults = response;
					//console.log("Pushed vaults.");
					self.pageIsLoading = false;

	            }
				//console.timeEnd('mounted');
            }).catch(error => {
            	console.error(error);
	            self.pageIsLoading = false;
            });

    },
    methods: {
	    portalTableSort(items, index, isDesc) {
	    	let self = this;

		    items.sort((a, b) => {
		    	let sortResult = 0;
		    	if (!index || Array.isArray(index) && index.length === 0) {
				    sortResult = a.name.localeCompare(b.name, 'en', { sensitivity: 'base' });
			    } else {
				    for (let i = 0; i < index.length; i++) {
					    let subIndex = index[i];
					    let subIsDesc = isDesc[i];

					    //console.log(`Sort index ${subIndex} descending ${JSON.stringify(subIsDesc)} is ${typeof subIsDesc}`);

					    if (self.isFavorite(a.id) && !self.isFavorite(b.id)) {
						    sortResult = -1;
					    } else if (!self.isFavorite(a.id) && self.isFavorite(b.id)) {
						    sortResult = 1;
					    } else if (subIndex === "name") {
						    if (!subIsDesc) {
							    sortResult = a.name.localeCompare(b.name, 'en', { sensitivity: 'base' });
						    } else {
							    sortResult = b.name.localeCompare(a.name, 'en', { sensitivity: 'base' });
						    }
					    } else if (subIndex === "_lastChangedAt") {
						    let latestA = a.last_upload_at > a.last_download_at ? a.last_upload_at : a.last_download_at;
						    let latestB = b.last_upload_at > b.last_download_at ? b.last_upload_at : b.last_download_at;

						    if (!subIsDesc) {
							    return latestA < latestB ? -1 : 1;
						    } else {
							    return latestB < latestA ? -1 : 1;
						    }
					    } else if (subIndex === "num_tasks_open" || subIndex === "earliest_open_task_date") {
							//If any tasks.. keep on top, then by date
							let aTotalTasks = a.num_tasks_open + a.num_tasks_closed;
							let bTotalTasks = b.num_tasks_open + b.num_tasks_closed;
							if (aTotalTasks !== 0 && bTotalTasks === 0) {
								sortResult = -1;
							} else if (aTotalTasks === 0 && bTotalTasks !== 0) {
								sortResult = 1;
							} else {
								if (!subIsDesc) {
									return a[subIndex] < b[subIndex] ? -1 : 1;
								} else {
									return b[subIndex] < a[subIndex] ? -1 : 1;
								}
							}
						} else if (subIndex) {
						    if (!subIsDesc) {
							    return a[subIndex] < b[subIndex] ? -1 : 1;
						    } else {
							    return b[subIndex] < a[subIndex] ? -1 : 1;
						    }
					    }

					    if (sortResult !== 0) {
						    break;
					    }
				    }
			    }

			    return sortResult;
		    });

		    return items;
	    },
    	toggleFavorite(vaultId, $event) {
    		let self = this;
			let test = self.isFavorite(vaultId);
			console.log(test);
		    vaultService.mutation("setVaultAsFavorite", {'vault_id': vaultId, 'remove_vault': self.isFavorite(vaultId)}).then(response => {
		    	//console.log("Returned from set vault as favorite. " + response);
			    vaultService.query("getUserById", undefined, "user", {vuexCache: false}).then(() => {});
		    }).catch(err => {
		    	console.dir(err);
			    self.notification('error', "Unable to mark vault as a favorite.");
		    });
	    },
    	isFavorite(vaultId) {
    		return !!this.user.favoriteVaults &&
			    this.user.favoriteVaults.indexOf(vaultId) > -1;
	    },
        momentDate(timestamp, dateOnly) {
            return moment.unix(timestamp / 1000).format(dateOnly ? "MM/DD/YYYY" : "MM/DD/YYYY hh:mm a");
        },
		yyyymmddTOmmddyyyy(timestamp, dateOnly) {
			let dateResult = null;
			if (timestamp) {
				let timeParts = timestamp.split("-");
				dateResult = timeParts[1] + "/" + timeParts[2] + "/" + timeParts[0];
			}
			return dateResult;
		},
        getVaultMemberInitials(vault) {
            let permissions = vault.permissions.filter(perm => perm._deleted !== 1);

            let initials = [];
            for (let p = 0; p < permissions.length; p++) {
                let username = permissions[p].user_name;
				let hexColor = toMaterialStyle(username);

				initials.push({
					initials: getInitials(username),
					class: {
						"background-color": permissions[p].invitation_accepted === "true" ? hexColor.backgroundColor : "lightgray",
						"color": permissions[p].invitation_accepted === "true" ? hexColor.color : "black"
					},
					username: permissions[p].invitation_accepted === "true" ? username : username + " (Pending)"
				});
            }

            return initials;
        },
        isVaultPendingInvitation(vault) {
            let vaultIsPendingInvitation = false;
            if (this.user && this.user.vaults && this.cognito && this.cognito.attributes['custom:mutableUserType'] === "guest") {
            	let foundVault = this.user.vaults.find(testVault => testVault.vault_id === vault.id && testVault.invitation_accepted === "none" && testVault._deleted !== 1);
            	if (foundVault) {
		            vaultIsPendingInvitation = true;
	            }
            }
            return vaultIsPendingInvitation;
        },
        acceptInvite(vault, loadVault) {
        	//console.log("Accept Invitation");
        	let self = this;
            vaultService.mutation("vaultInvite", {vaultId: vault.id, acceptInvite: true}).then(() => {
	            self.notification('success', "Vault invite accepted.");
                if (loadVault === true) {
	                self.$router.push(`/org/${vault.org_id}/vault/${vault.id}/folder/main`);
                } else {
	                vaultService.query("getUserById", undefined, "user", {vuexCache: false}).then(() => {
		                vaultService.query("getVaultsByOrgId", undefined, "vaults", {vuexCache: false});
	                });
                }
            }).catch(err => {
	            self.notification('error', "Unable to accept invite");
            });
        },
        denyInvite(vault) {
            vaultService.mutation("vaultInvite", {vaultId: vault.id, acceptInvite: false}).then(() => {
                vaultService.query("getUserById", undefined, "user", {vuexCache: false});
            }).catch(err => {
                this.notification('error', "Unable to dismiss invite");
            });
        },
        deleteVault(vault) {
            vaultService.mutation("deleteVault", {vault_id: vault.id}).then(response => {
                this.notification('success', "Vault Deleted!");
                try {
					woopra.track("Deleted Vault", {Vault: vault.id, OrgId: this.organization.id ? this.organization.id : null });
				} catch (woopraError){
					console.error("Woopra not loaded.");
				}
                vaultService.query("getVaultsByOrgId", undefined, "vaults", {vuexCache: false});
            }).catch(err => {
                this.notification('error', 'Error deleting selected vault.');
            });
        },
        createNewVault() {
            if (this.newVaultName) {
                vaultService.mutation("createVault", {name: this.newVaultName.trim(), org_id: this.organization.id}).then(result => {
					try {
						woopra.track("Portal Created", {Portal_Name : this.newVaultName, OrgId : this.organization.id});
					} catch (woopraError) {
						console.error("Woopra not loaded.");
					}
                    this.newVaultName = "";

                    this.notification('success', "Portal Created!");
                    vaultService.query("getVaultsByOrgId", undefined, "vaults", {vuexCache: false});
                }).catch(err => {
                    this.notification('error', "Error creating new Client Portal.");
                });
            } else {
                this.notification('error', "Please enter a name for the new Client Portal.");
            }
        },
	    signOutUser() {
		    this.cache = {};
		    Auth.signOut({ global: true })
			    .then(data => {
				    EventBus.$emit('authState', {
					    status: 'signedOut'
				    });
			    })
			    .catch(err => console.log(err));
	    },
		togglePrivacyMode(value) {
			this.setLocalStoreValue("privateMode", value);
		},
		setLocalStoreValue(cname, cvalue) {
			localStorage.setItem(cname, cvalue);
		},
		getLocalStoreValue(cname) {
			let cvalue = null;
			if (localStorage[cname] === "true") {
				cvalue = true;
			} else if (localStorage[cname] === "false") {
				cvalue = false;
			} else {
				cvalue = localStorage[cname];
			}
			return cvalue;
		}
    }
});

function getInitials(value) {
    let initials = [];
    try {
	    initials = value.toUpperCase().replace(/[^a-zA-Z- ]/g, "").match(/\b\w/g);
	    if (initials === null || initials.length === 0) {
		    initials = value.substring(0, 1).toUpperCase();
	    }
    } catch(e) {
    	console.error("Unable to get initials.");
    }

    return initials.join('');
}

function stringToColour(str) {
    var hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    var colour = '#';
    for (let i = 0; i < 3; i++) {
        let value = (hash >> (i * 8)) & 0xFF;
        colour += ('00' + value.toString(16)).substr(-2);
    }
    return colour;
}

function lightOrDark(color) {

    // Variables for red, green, blue values
    var r, g, b, hsp;

    // Check the format of the color, HEX or RGB?
    if (color.match(/^rgb/)) {

        // If RGB --> store the red, green, blue values in separate variables
        color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

        r = color[1];
        g = color[2];
        b = color[3];
    } else {

        // If hex --> Convert it to RGB: http://gist.github.com/983661
        color = +("0x" + color.slice(1).replace(
            color.length < 5 && /./g, '$&$&'));

        r = color >> 16;
        g = color >> 8 & 255;
        b = color & 255;
    }

    // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
    hsp = Math.sqrt(
        0.299 * (r * r) +
        0.587 * (g * g) +
        0.114 * (b * b)
    );

    // Using the HSP value, determine whether the color is light or dark
    if (hsp > 127.5) {

        return 'light';
    } else {

        return 'dark';
    }
}

const toBase64 = file => new Promise((resolve, reject) => {
	const reader = new FileReader();
	reader.readAsArrayBuffer(file);
	reader.onload = () => resolve(btoa(String.fromCharCode.apply(null, new Uint8Array(reader.result))));
	reader.onerror = error => reject(error);
});
